.loginFrame {
    position: relative;
    height: auto;
    width: 400px;
    background-color: #2D2D2D;
    margin: auto;
    /* margin-top: calc(100vh - 40%); */
    z-index: 4;
    border-radius: 8px;
    display: block;
    overflow: auto;
    margin-top: 128px;
}

.errorText {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 24px;
    color: rgb(255, 116, 116);
}

@media only screen and (max-width: 670px) {
    .loginFrame {
        height: 261px;
        width: 300px;
    }
}

.loginBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.loginMessageTextView {
    font-size: 20px;
    color: white;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.emailField {
    border-radius: 4px;
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    position: relative;
    width: calc(100% - 50px);
    height: 30px;
    margin-top: 24px;
    text-align: center;
    border: none;
    outline: none;
    font-size: 16px;
 }

.emailField:focus {
    outline: 2px solid #120ee8;
}

.passwordField {
    border-radius: 4px;
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    position: relative;
    height: 30px;
    width: calc(100% - 50px);
    margin-top: 10px;
    text-align: center;
    border: none;
    outline: none;
    font-size: 16px;
}

.passwordField:focus {
    outline: 2px solid #120ee8;
}

.loginButton {
    border-radius: 4px;
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    position: relative;
    width: calc(100% - 50px);
    margin: 24px;
    height: 36px;
    right: 0;
    bottom: 0;
    background-color: #000000;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
}

.centeredButton {
    margin-left: auto;
    margin-right: auto;
}

.loginButton:hover {
    background-color: #120ee8;
}

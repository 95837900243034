.navBarLogo {
    width: 50px;
    height: 50px;
    margin: 3px 0 0 10px;
    cursor: pointer;
    padding: 2px;
}

.navBarTitle {
    text-align: left;
    width: 250px;
    color: white;
    font-size: 20px;
    margin: 2px 0 0 8px;
    cursor: pointer;
}

.navBarTitleTopLine {
    text-align: left;
    font-size: 27px;
}

.navBarTitleBottomLine {
    text-align: left;
    font-size: 14px;
}

.navBarSpacer {
    height: 100%;
    width: 2px;
    background-color: white;
    min-width: 2px;
}

.navBarTab {
    color: white;
    height: 100%;
    line-height: 56px;
    cursor: pointer;
    margin: 0 24px;
    text-align: center;
    float: right;
    min-width: 86px;
    font-weight: bold;
}

.navBarContainer {
    position: relative;
    height: 56px;
    background-color: #00000044;
    display: flex;
    left: 0;
}

.navBarTabContainer {
    float: right;
    position: relative;
    justify-items: right;
    display: flex;
    height: 50%;
    margin: auto;
    right: 0;
}

@media only screen and (max-width: 800px) {
    .navBarLogo {
        width: 30px;
        height: 30px;
        margin: 9px 0 0 8px;
    }
    .navBarTab {
        margin: 0 16px;
    }
    .navBarTitle {
        width: 114px;
        font-size: 20px;
        margin: 11px 0 0 8px;
    }
    .navBarTitleTopLine {
        font-size: 17px;
    }
    .navBarTitleBottomLine {
        font-size: 9px;
    }
    .navBarTab {
        min-width: 50px;
        margin: 0 14px;
    }
}

.bookingPage {
    background-color: #120ee8;
    width: 100%;
    top: 56px;
    bottom: 0;
    font-family: 'Nunito', sans-serif;
}

.formDatePicker {
    color: black;
}

.topContainer {
    display: inline-flex;
    width: 80%; 
    margin: auto;
    margin-top: 20px;
    color: white;
    font-size: 20px;
    max-width: 700px;
}

.calendarContainer {
    margin: auto;
}

.timeContainer {
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 300px;
}

.priceContainer {
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    display: table;
    table-layout: fixed;
    width: 220px;
    max-width: 100vh;
    color: #FFEF00;
    font-weight: bold;
}

.react-datepicker-wrapper {
    width: 100%;
}

.firstNameContainer {
    width: 49%;
    float: left;
    margin-bottom: 8px;
}

.lastNameContainer {
    width: 49%;
    float: right;
    margin-bottom: 8px;
}

input[type='radio'] { 
    height: 16px;
    width: 16px;
    display: inline-block;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #d2d2d2;
    border-radius: 100%;
}

input[type=radio]:disabled+label {
    color: #FFFFFF55;
}

@media only screen and (max-width: 800px) {
    .topContainer {
        display: block;
    }

    .firstNameContainer {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
    
    .lastNameContainer {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}
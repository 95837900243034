.bookingUnavailablePage {
    background-color: #120ee8;
    width: 100%;
    top: 56px;
    bottom: 0;
    font-family: 'Nunito', sans-serif;
}
.messageText {
    color: white;
    font-size: 20px;
    width: 80%; 
    margin: auto;
    margin-top: 20px;
    text-align: center;
}
.requirementList {
    color: #FFEF00;
    font-size: 20px;
    width: 240px;
    margin: auto;
    margin-top: 40px;
    text-align: left;
    float: center;
}

@media only screen and (max-width: 800px) {
    .messageText {
        width: 92%;
        font-size: 17px;
        text-align: left;
    }
}
.eventViewFrame {
    position: relative;
    height: auto;
    width: 320px;
    background-color: #ffffff;
    /* margin-top: calc(100vh - 50%); */
    z-index: 101;
    border-radius: 8px;
    display: block;
    overflow: auto;
    padding: 16px;
    margin: auto;
    margin-top: 128px;
}

.eventViewSpacer {
    height: 32px;
}

.eventViewBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.deleteButton {
    border-radius: 4px;
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    position: relative;
    width: calc(100% - 50px);
    margin: 24px;
    height: 36px;
    right: 0;
    bottom: 0;
    background-color: rgb(180, 23, 23);
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
}

.addButton {
    border-radius: 4px;
    font-family: Helvetica, Verdana, Arial, "sans-serif";
    position: relative;
    width: calc(100% - 50px);
    margin: 24px;
    height: 36px;
    right: 0;
    bottom: 0;
    background-color: #120ee8;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
}
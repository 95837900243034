@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

.App {
  text-align: center;
  font-family: Times, 'Anton', Impact, sans-serif;
}

.AppViewContainer {
  background-color: #000000;
  position: absolute;
  height: 100%;
  width: 100%;
}

a {
  color: #FEAAA0;
}

.contactPage {
    background-color: #120ee8;
    width: 100%;
    top: 56px;
    bottom: 0;
    font-family: 'Nunito', sans-serif;
}
.form {
    width: 80%; 
    margin: auto;
    margin-top: 20px;
    color: white;
    font-size: 20px;
    max-width: 700px;
}
.hidden {
    display: none;
}
.messageText {
    color: white;
    font-size: 20px;
    width: 80%; 
    margin: auto;
    margin-top: 20px;
}
.errorText {
    color: rgb(255, 59, 59);;
    font-size: 16px;
    float: left;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 6px;
    font-weight: 800;
}
.formLabelText {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    font-weight: bold;
}
.submitButton {
    -webkit-appearance: none;
    appearance: none;
}

.selector {
    background-color: white;
}
.numberOfRoomsDescriptionText {
    margin-top: 2px;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    font-size: 16px;
}

textarea {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 110px;
    font-size: 16px;
    resize: vertical;
    overflow: auto;
}

input[type=number], select {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    font-size: 16px;
}

input[type=text], select {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    font-size: 16px;
}
  
input[type=submit] {
    width: 100%;
    background-color: #FFEF00;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    margin-top: 10px;
    font-size: 20px;
    outline-width: 0;
    margin-bottom: 16px;
}
  
input[type=submit]:hover {
    background-color: #FFEF00;
}

input:disabled,
input[disabled] {
  opacity: 50%;
}

@media only screen and (max-width: 800px) {
    .messageText {
        width: 92%;
        font-size: 17px;
    }
    .form {
        font-size: 17px;
    }
}

.rbc-timeslot-group {
    min-height: 60px !important;
}
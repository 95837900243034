.homePage {
    width: 100%;
    background-color: #120ee8;
    padding-bottom: 0;
}
.column {
    float: left;
    width: 33.33%;
}
.columnLarge {
    float: left;
    width: 40%;
}
.columnSmall {
    padding-top: 14%;
    float: left;
    width: 20%;
}
.row::after {
    content: "";
    clear: both;
    display: table;
}
body {
    color: #FFEF00;
    position: absolute;
    margin:0px;
    padding:0px;
    width:100%;
    overflow-y: scroll;
}
.houseImages {
    margin: auto;
    margin-bottom: 28px;
    max-width: 700px;
}
.residentialHouse {
    margin: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    margin-bottom: 32px;
}
.esb {
    margin: auto;
    width: 90%;
    margin-top: 40px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}
.button {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 10;
}
.button img{
    display: block;
    width:100%;
    height:auto;
    max-height:100%;
}
.phNum {
    font-size: 100px;
    text-align: center;
    color: #FFEF00;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 200;
}
.bookOnlineLink {
    text-decoration: underline;
    font-size: 40px;
    text-align: center;
    color: #FFEF00;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 50px;
    font-weight: 10;
    letter-spacing: 3px;
}
a {
    color: #FFEF00;
    text-decoration: none;
}
.eoLTitle {
    font-size: 155px;
    text-align: center;
    color: #FFEF00;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 300;
}
.ccbTitle {
    font-size: 80px;
    text-align: center;
    color: white;
    margin: auto;
    margin-top: 0;
    padding-top: 0;
    font-weight: 300;
    max-width: 600px;
}
.indexTitleDiv {
    margin: auto;
    width: 100%;
}
.priceDiv {
    float: right;
    text-align: left;
    margin-top: 32px;
    padding-right: 8%;
}
.priceTable {
    font-size: 40px;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}
.stepsDiv {
    float: left;
    margin-top: 32px;
    padding-left: 8%;
}
.stepsTable {
    font-size: 40px;
    font-family: 'Nunito', sans-serif;
}
.space {
    width: 100%;
    height: 460px;
}
.cellpadding {
    padding-right: 20px;
}
.stepsExtra {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 60px;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 0;
}
.or {
    width: 100%;
    font-size: 155px;
    text-align: center;
    color: #FFEF00;
    padding-bottom: 0;
    margin-bottom: 0px;
    font-weight: 300;
}
.residential {
    width: 100%;
    font-size: 80px;
    text-align: center;
    color: white;
    margin-top: 0px;
    padding-bottom: 0;
    margin-bottom: 40px;
    font-weight: 300;
}
.maybe {
    width: 100%;
    text-align: center;
    font-size: 125px;
    color: #FFEF00;
    margin-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0px;
    font-weight: 300;
}
.commercial {
    width: 100%;
    text-align: center;
    font-size: 65px;
    color: white;
    margin-top: 0px;
    padding-bottom: 0;
    margin-bottom: 0px;
    font-weight: 300;
}
.bottomSpace {
    height: 80px;
}

@media only screen and (min-device-width: 767px) {
    .button {
        visibility: hidden;
        display: none;
        cursor: pointer;
    }
    .bottomSpace {
        height: 0px;
    }
}

@media only screen and (max-width: 1236px) {
    .priceDiv {
        float: none;
        width: 70%;
        margin: auto;
        margin-top: 25px;
        padding: 0;
    }
    .stepsDiv {
        margin:auto;
        float: none;
        margin-top: 25px;
        width: 100%;
        padding: 0;
    }
    table {
        margin:0 auto 0 auto;
    }
    .space {
        height: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .eoLTitle {
        font-size: 58px;
        margin: auto;
        width: 100%;
    }
    .ccbTitle {
        font-size: 34px;
        margin: auto;
        max-width: 280px;
    }
    .phNum {
        font-size: 38px;
    }
    .bookOnlineLink {
        font-size: 32px;
        font-weight: 100;
        letter-spacing: 4px;
        padding-bottom: 10px;
    }
    .stepsExtra {
        margin-top: 0;
        font-size: 26px;
    }
    .priceTable {
        font-size: 22px;
    }
    .stepsTable {
        font-size: 19px;
        font-weight: 500;
    }
    .or {
        font-size: 44px;
    }
    .residential {
        font-size: 26px;
    }
    .maybe {
        font-size: 44px;
    }
    .commercial {
        font-size: 26px;
    }
    .space {
        height: 30px;
    }
    .esb {
        margin-bottom: 148px;
    }
}

.calendarPage {
    background-color: gray;
    color: black;
    width: 100%;
    top: 56px;
    bottom: 0;
    font-family: 'Nunito', sans-serif;
}

.calendarToolbar {
    display: flex;
    background-color:#5d5e5d;
    height: 40px;
    padding: 8px;
}

.switchLabel {
    padding: 8px;
    vertical-align: bottom;
    color: white;
    margin: auto;
}

.eventTitleText {
    margin-top: 16px;
}

.rbc-calendar {
    background-color: white;
    min-height: calc(100vh - 56px - 56px);
    height: 100% !important;
}

/* Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
.FooterContainer {
    display: block;
    position: relative;
    background-color: #00000044;
    width: 100%;
    height: 20px;
    font-family: 'Nunito', sans-serif;
    color: white;
    font-size: 14px;
}
.FooterLinkContainer {
    display:inline-block;  
    width: auto;
    margin: auto;
}
.FooterLinkText {
    display:inline-block;
    font-size: 14px;
    padding: 2px;
    text-decoration: underline;
    cursor: pointer;
}
.FooterText {
    display:inline-block;
    font-size: 14px;
    padding: 2px;
}
.FooterSpace {
    width: 20px;
}